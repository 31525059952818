import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GoogleLogin from 'react-google-login';

const responseGoogleSuccess = response => {
  alert(JSON.stringify(response));
};

const responseGoogleFailure = response => {
  alert(JSON.stringify(response));
};

ReactDOM.render(
  <GoogleLogin
  clientId="608155304059-jmijoigveg64aogoua81nh3jqps4sq1p.apps.googleusercontent.com"
  buttonText="Login with Google"
  onSuccess={responseGoogleSuccess}
  onFailure={responseGoogleFailure}
  cookiePolicy="single_host_origin"
  />,
  
  document.getElementById('root')
);  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

